<template>
  <v-container grid-list-md :px-0="$vuetify.breakpoint.mdAndUp">
    <h2 class="text-center">Register as a Client</h2>
    <p class="text-center">Sign-up to get legal help from licensed lawyers in the Philippines.</p>
    <v-card class="pa-4">
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="12" sm="6" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 pr-1': 'pb-0 pt-0'">
            <v-text-field
              outlined
              :rules="[rules.required]"
              v-model="user.fullName"
              label="Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pl-1 pb-0' : 'pb-0 pt-0'">
            <v-text-field
              outlined
              :rules="[rules.required, rules.email]"
              v-model="user.email"
              label="E-Mail"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" :class="$vuetify.breakpoint.mdAndUp? 'pb-0 pt-0 pr-1' : 'pb-0 pt-0'">
            <v-text-field
              outlined
              :rules="[rules.required]"
              v-model="user.password"
              :append-icon="showPassword ? 'visibility_off' : 'visibility'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              label="Password"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 pt-0 pl-1': 'pb-0 pt-0'">
            <v-text-field
              outlined
              :rules="[rules.required]"
              mask="####-####-####"
              label="Mobile Phone"
              v-model="user.contacts[0]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 pt-0 pr-1' : 'pb-0 pt-0'">
            <v-combobox
              outlined
              :rules="[rules.required]"
              :items="provinces"
              v-model="user.province"
              label="Province"
              @change="populateCities"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="6" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 pt-0 pl-1' : 'pb-0 pt-0'">
            <v-select
              outlined
              :rules="[rules.required]"
              :items="cities"
              v-model="user.city"
              label="City or Municipality"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="12" class="pa-0 px-3">
            <v-row justify="center">
              <v-checkbox class="mt-0" :rules="checkBoxRule" v-model="checkBox">
                <span slot="label">
                  I agree to the
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <a style="text-decoration: none;" slot="activator" target="_blank" href="/terms-and-conditions" @click.stop v-on="on">Terms & Conditions.</a>
                    </template>
                    <span>Opens in new tab</span>
                  </v-tooltip>
                </span>
              </v-checkbox>
            </v-row>
            <v-btn
              :loading='this.btnLoading'
              @click="registerUser()"
              color="primary"
              large
              depressed
              :block="$vuetify.breakpoint.smAndDown"
            >Sign-Up</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService'
import Philippines from 'philippines'
import rulesMixin from '@/mixins/rulesMixin'

export default {
  name: 'ClientRegistrationForm',
  mixins: [
    rulesMixin
  ],
  data () {
    return {
      checkBoxRule: [(v) => v === true || 'You must agree to our Terms & Conditions'],
      checkBox: '',
      btnLoading: false,
      showPassword: false,
      provinceKey: '',
      provinces: [],
      cities: [],
      user: {
        fullName: '',
        email: '',
        password: '',
        province: '',
        city: '',
        contacts: []
      }
    }
  },
  methods: {
    populateProvinces () {
      for (var i = 0; i < Philippines.provinces.length; i++) {
        this.provinces.push(Philippines.provinces[i].name)
      }
      this.provinces.sort()
    },
    populateCities () {
      this.cities = []
      var selectedProvinceKey
      for (var i = 0; i < Philippines.provinces.length; i++) {
        if (Philippines.provinces[i].name === this.user.province) {
          selectedProvinceKey = Philippines.provinces[i].key
        }
      }
      for (var x = 0; x < Philippines.cities.length; x++) {
        if (Philippines.cities[x].province === selectedProvinceKey) {
          this.cities.push(Philippines.cities[x].name)
        }
      }
    },
    registerUser () {
      if (this.$refs.form.validate()) {
        this.btnLoading = true
        AuthService.registerAsClient({ user: this.user })
          .then(() => {
            this.$store.dispatch('auth/SET_TOKEN', {
              email: this.user.email,
              password: this.user.password
            })
              .then(() => {
                this.$swal({
                  title: 'Success!',
                  text: 'You have successfully created your account!',
                  type: 'success',
                  confirmButtonColor: '#5F27CD',
                  confirmButtonText: 'Proceed',
                  onOpen: () => { document.activeElement.blur() }
                })
                  .then((result) => {
                    if (result.value) {
                      this.$router.push('/post-problem')
                    }
                  })
              })
          })
          .catch(err => {
            this.$swal({
              type: 'error',
              text: err.response ? err.response.data : 'There is a problem connecting to the server.',
              onOpen: () => { document.activeElement.blur() }
            })
          })
          .finally(() => { this.btnLoading = false })
      }
    }
  },
  mounted () {
    this.populateProvinces()
  }
}
</script>
