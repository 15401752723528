<template>
  <v-container grid-list-md :px-0="$vuetify.breakpoint.mdAndUp">
    <h2 class="text-center">Register as a Lawyer</h2>
    <p class="text-center">Sign-up to be hired by clients needing legal assistance.</p>
    <v-card class="pa-4">
      <v-form enctype="multipart/form-data" ref="form" lazy-validation>
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 pr-1' : 'pb-0'">
            <v-text-field
              outlined
              label="Name"
              :rules="[rules.required]"
              v-model="user.fullName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pl-1 pb-0' : 'pb-0'">
            <v-text-field
              outlined
              label="E-Mail"
              :rules="[rules.required, rules.email]"
              v-model="user.email"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 pt-0 pr-1' : 'pb-0 pt-0'">
            <v-text-field
              outlined
              label="Password"
              :rules="[rules.required]"
              v-model="user.password"
              :append-icon="showPassword ? 'visibility_off' : 'visibility'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 pt-0 pl-1' : 'pb-0 pt-0'">
            <v-text-field
              outlined
              persistent-hint
              mask="####-####-####"
              label="Mobile Phone"
              hint="We will be contacting you in this number for verification."
              :rules="contactRule"
              v-model="user.contacts[0]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 pt-0 pr-1' : 'pb-0 pt-0'">
            <v-combobox
              outlined
              label="Province"
              :rules="[rules.required]"
              :items="provinces"
              v-model="user.province"
              @change="populateCities"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="6" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 pt-0 pl-1' : 'pb-0 pt-0'">
            <v-select
              outlined
              label="City or Municipality"
              :rules="[rules.required]"
              :items="cities"
              v-model="user.city"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pb-0 pt-0">
            <v-row no-gutters>
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12' : '4'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-checkbox v-model="user.specialization" value="General Practitioner" label="General Practitioner" :disabled="isGeneral" @change="isDisabled = !isDisabled" v-on="on"></v-checkbox>
                  </template>
                  <span>Once ticked, you cannot select other specializations.</span>
                </v-tooltip>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12' : '8'">
                <v-select
                  outlined
                  multiple
                  chips
                  label="Specialization"
                  :disabled="isDisabled"
                  :rules="specsRule"
                  :items="specialization"
                  @change="limiter"
                  v-model="user.specialization"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" class="text-left pb-0 pt-0">
            <v-tooltip right color="primary" >
              <template v-slot:activator="{ on }">
                <span class="body-2">Lawyer Details <v-icon small v-on="on" color="grey">info</v-icon></span>
              </template>
              <span>Information you entered shall be kept confidential by our team. This will only be used for verification purposes.</span>
            </v-tooltip>
          </v-col> -->
          <v-col cols="12" sm="6" md="3" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 pr-1' : 'pb-0'">
            <v-text-field
              outlined
              mask="######"
              label="Roll Number"
              :rules="[rules.required]"
              v-model="user.rollNumber"
            >
              <v-tooltip color="primary" slot="prepend" right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information</v-icon>
                </template>
                <span>Information you entered shall be kept confidential by our team. </span><br />
                <span>This will only be used for verification purposes.</span>
              </v-tooltip>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 pl-1 pr-1' : 'pb-0'">
            <v-text-field
              outlined
              mask="####"
              label="Year Admitted"
              :rules="[rules.required]"
              v-model="user.yearAdmitted"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 pl-1' : 'pb-0'">
            <v-file-input 
              v-model="file"
              label="Valid government-issued ID" 
              placeholder="Attach file"
              accept="image/*, text/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation"
              :rules="imageSizeRule"
              outlined 
              show-size
              large
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
            >
            </v-file-input>
          </v-col>
          <v-col cols="12" sm="6" md="12" class="pa-0">
            <v-row justify="center">
              <v-checkbox class="mt-0" :rules="checkBoxRule" v-model="checkBox">
                <span slot="label">
                  I agree to the
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <a style="text-decoration: none;" slot="activator" target="_blank" href="/terms-and-conditions" @click.stop v-on="on">Terms & Conditions.</a>
                    </template>
                    <span>Opens in new tab</span>
                  </v-tooltip>
                </span>
              </v-checkbox>
            </v-row>
            <v-btn
              :loading='this.btnLoading'
              @click="registerLawyer()"
              color="primary"
              large
              depressed
              :block="$vuetify.breakpoint.smAndDown"
            >Sign-Up</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import io from 'socket.io-client'
import AuthService from '@/services/AuthService'
import Philippines from 'philippines'
import specializationMixin from '@/mixins/specializationMixin'
import rulesMixin from '@/mixins/rulesMixin'

export default {
  name: 'LawyerRegistrationForm',
  mixins: [
    rulesMixin,
    specializationMixin
  ],
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      registrant: 1,
      type: 'Lawyer Registration',
      imgSrc: '',
      fileName: '',
      file: [],
      imageSizeRule: [ value => !value || value.size < 2000000 || 'Image size should be less than 2 MB.' ],
      imageRule: [(v) => !!v || 'Please enter an image.'],
      contactRule: [(v) => !!v || 'Please enter a mobile phone number so that we can verify you.'],
      specsRule: [(v) => v.length >= 1 || 'Plesase enter at least one specialization.'],
      checkBoxRule: [(v) => v === true || 'You must agree to our Terms & Conditions'],
      checkBox: '',
      btnLoading: false,
      showPassword: false,
      isDisabled: false,
      isGeneral: false,
      provinces: [],
      cities: [],
      provinceKey: '',
      user: {
        fullName: '',
        email: '',
        password: '',
        province: '',
        city: '',
        rollNumber: '',
        yearAdmitted: '',
        specialization: [],
        isLawyer: '',
        isLawyerVerified: '',
        contacts: []
      }
    }
  },
  methods: {
    limiter (e) {
      if (e.length > 0) {
        this.isGeneral = true
      }
      else if (e.length === 0) {
        this.isGeneral = false
      }
      if (e.length > 3) {
        this.$swal({
          type: 'error',
          text: 'You can only select three specializations.'
        })
        e.pop()
      }
    },
    populateProvinces () {
      for (var i = 0; i < Philippines.provinces.length; i++) {
        this.provinces.push(Philippines.provinces[i].name)
      }
      this.provinces.sort()
    },
    populateCities () {
      this.cities = []
      var selectedProvinceKey
      for (var i = 0; i < Philippines.provinces.length; i++) {
        if (Philippines.provinces[i].name === this.user.province) {
          selectedProvinceKey = Philippines.provinces[i].key
        }
      }
      for (var x = 0; x < Philippines.cities.length; x++) {
        if (Philippines.cities[x].province === selectedProvinceKey) {
          this.cities.push(Philippines.cities[x].name)
        }
      }
    },
    pickFile () {
      this.$refs.image.click()
    },
    onFilePicked (e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0]
      }

      if (this.file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        this.imgSrc = ''
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          this.fileName = e.target.files[0].name
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(this.file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    registerLawyer () {
      if (this.$refs.form.validate()) {
        this.btnLoading = true
        let formData = new FormData()
        formData.append('fullName', this.user.fullName)
        formData.append('email', this.user.email)
        formData.append('password', this.user.password)
        formData.append('province', this.user.province)
        formData.append('city', this.user.city)
        formData.append('yearAdmitted', this.user.yearAdmitted)
        formData.append('rollNumber', this.user.rollNumber)
        formData.append('contacts', this.user.contacts)
        formData.append('ibpCard', this.file)
        for (var i = 0; i < this.user.specialization.length; i++) {
          formData.append('specialization[]', this.user.specialization[i])
        }

        AuthService.registerLawyer({ formData })
          .then(() => {
            this.$store.dispatch('auth/SET_TOKEN', {
              email: this.user.email,
              password: this.user.password
            })
              .then(() => {
                this.socket.emit('registration', {
                  type: this.type,
                  count: this.registrant
                })
                this.$swal({
                  title: 'Success!',
                  text: 'You have successfully created your account!',
                  type: 'success',
                  confirmButtonColor: '#5F27CD',
                  confirmButtonText: 'Proceed',
                  onOpen: () => { document.activeElement.blur() }
                })
                  .then((result) => {
                    if (result.value) {
                      this.$router.push('/overview')
                    }
                  })
              })
          })
          .catch(err => {
            this.$swal({
              type: 'error',
              text: err.response ? err.response.data : 'There is a problem connecting to the server.',
              onOpen: () => { document.activeElement.blur() }
            })
          })
          .finally(() => { this.btnLoading = false })
      }
    }
  },
  mounted () {
    this.populateProvinces()
  }
}
</script>

<style scoped>
  .rounded-card{
    border-radius: 12px;
  }
  .dropbox {
    outline: 2px dashed #c8d6e5; /* the dash box */
    outline-offset: -10px;
    background: #5f27cd;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  .dropbox:hover {
    background: #7240d3; /* when mouse over to the drop zone, change color */
  }
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>
