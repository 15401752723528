<template>
  <v-container fluid :px-0="$vuetify.breakpoint.smAndDown">
    <Header />
    <v-row justify="center" mb-5 :viewport-height="$vuetify.breakpoint.lgAndUp">
      <v-col cols="12" sm="12" md="9" lg="6" xl="5">
        <v-col cols="12" pt-3>
          <div class="home_header_buttons text-center">
            <v-btn @click="registerOption(1)" depressed :color="btnLeftColor" class="btn_home">Lawyer</v-btn>
            <v-btn @click="registerOption(0)" depressed :color="btnRightColor" class="btn_home">Client</v-btn>
          </div>
        </v-col>
        <v-col cols="12" class="text-center">
          <transition name="fade" mode="out-in">
            <LawyerCard v-if="$store.getters['control/registerTabIndex'] == 1" />
            <ClientCard v-else />
          </transition>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Navigation from '@/components/RegisterComponents/Navigation'
import Header from '@/components/IndexComponents/Header'
import ClientCard from '@/components/RegisterComponents/ClientCard'
import LawyerCard from '@/components/RegisterComponents/LawyerCard'

export default {
  components: {
    ClientCard,
    LawyerCard,
    Navigation,
    Header
  },
  data () {
    return {
      btnLeftColor: '',
      btnRightColor: ''
    }
  },
  methods: {
    registerOption (index) {
      if (index === 0) {
        this.$store.commit('control/SET_REGISTER_TAB_INDEX', index)
        this.btnRightColor = 'primary'
        this.btnLeftColor = 'grey lighten-2'
      } else {
        this.$store.commit('control/SET_REGISTER_TAB_INDEX', index)
        this.btnRightColor = 'grey lighten-2'
        this.btnLeftColor = 'primary'
      }
    }
  },
  mounted () {
    if (this.$store.getters['control/registerTabIndex'] === 0) {
      this.btnRightColor = 'primary'
      this.btnLeftColor = 'grey lighten-2'
    } else {
      this.btnLeftColor = 'primary'
      this.btnRightColor = 'grey lighten-2'
    }
  }
}
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .25s
  }
  .fade-enter, .fade-leave-to {
    opacity: 0
  }
  .home_header_buttons {
    margin-left: -16px;
  }
  .home_header_buttons .btn_home {
    position: relative;
    text-transform: uppercase;
    font-size: 15px;
    margin-right: -3px;
    line-height: 13px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .home_header_buttons .btn_home:first-child {
    border-right: none;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  .home_header_buttons .btn_home:first-child::after {
    position: absolute;
    display: block;
    right: 0;
    z-index: 1;
  }
  .home_header_buttons .btn_home:last-child {
    border-left: none;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-right: 50px;
  }
  .viewport-height {
    height: 860px;
  }
</style>
